import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from '../components/pageheading'
import ContentImage from '../components/contentimage'



const AboutUs = ({data}) => (

    <Layout pageClass="info-page about-us">
    <SEO title="About Us" description="Over 30 years of building experience and delivery of hundreds of high-quality residences." />
    <PageHeading title="About Us" imageData={data.headerImage} />
    <main className="main">
        <div>
        <div className="typography inner">

            <div className="text-holder">
                <h2>Quality Built on Experience</h2>
                <p>
                    With over 30 years’ experience in construction, Bryan Wheatcroft has helped deliver high-quality residences for hundreds of clients. At Modus Developments he has built a team that takes responsibility at every stage. We don’t outsource work to project managers or contract builders, who may have half an eye on the next job. Instead, we’ll stay with you throughout your project, and we don’t leave the site until you’re happy. 
                </p>

                <p>
                    The architects and clients we work with value our ability to plan meticulously and put together budgets that stand up to analysis. We’re not stumped by a challenging design, and our experience means we can spot issues before they become problems. Place a set of concept drawings in front of us, and we’ll offer constructive ideas on techniques, materials and programmes. By getting involved early – and understanding what’s important to you – we can help ensure a smooth path to an outstanding result.
                </p>
                
                <p>
                    All our site supervisors are <a href="https://www.lbp.govt.nz/">Licensed Building Practitioners</a>, and you’ll have the same team on your job from start to finish. Many of our team members have been with us for a decade or more. We also give back to the industry, by taking on apprentices and investing in their training. 
                </p>

                <p className="last">
                    For additional peace of mind, Modus Developments is a member of <a href="https://www.masterbuilder.org.nz/">Master Builders</a> and able to offer the <a href="https://www.masterbuilder.org.nz/RMBA/Master_Build_Guarantee/RMBA/MB_Guarantee/GuaranteeHome.aspx?hkey=de56f05d-5503-4317-92fa-1f0613872652">Master Build 10-Year Guarantee</a>. 
                </p> 
            </div>
            <ContentImage image={data.contentImage} alt="Room view through back" />
        </div>
        <div className="testimonials-about typography">
            <h2>What People Say</h2> 
            <div className="inner">
                <blockquote>
                    <p>
                        “Hulena Architects provided Contract Administration and Observation services for a large residential alteration and extension involving significant site work in which Modus Developments were the main contractor. Bryan’s estimate of the tender documentation was accurate which often isn’t the case with cost reimbursement contracts. He brought a wealth of knowledge and experience to the project and communication was excellent throughout. Proactive problem-solving by the team at Modus was appreciated and issues presented by the existing residence that arose on site were dealt with promptly using cost-effective and innovative solutions. Modus engaged with a separate contractor who were building a large stables simultaneously on site, co-ordinating work across both projects successfully and with ease. The end result was a high-quality build with excellent finishing and workmanship and a satisfied client. 
                    </p>

                    <p>
                        “From my experience I would without a doubt recommend Modus Developments as an honest and reliable contractor committed to delivering a successful outcome - they were a pleasure to work with.”
                    </p>

                    <cite>
                        -	Michelle Dyer, Hulena Architects
                    </cite>
                </blockquote>
                <blockquote>
                    <p>
                        “When deciding who should build our new home we were very much influenced by Michele’s three previous experiences with Modus. We were looking for a high quality build of an architecturally designed house with a number of bespoke features. The result is outstanding in terms of the quality of the build, but also the whole experience of working with Modus Developments. 
                    </p>

                    <p>
                        “We chose to go with a charge-up contract, whereby Bryan prepared a detailed budget, reported monthly against it, and completed the project at minimal variation to his budget. We found that this ‘open-book’ approach encouraged cooperation and communication between contractor and client throughout the project.”
                    </p>

                    <cite>
                        -	Michele and Barry, Home owners
                    </cite>
                </blockquote>
            </div>
        </div>
        
        </div>
    </main>
  </Layout>

)
export const pageQuery = graphql`
     {
        headerImage: imageSharp(resolutions: {originalName: {eq: "header-crop_43A2025.jpg"}}) { 
            ...ResponsiveImage
        }
        contentImage: imageSharp(resolutions: {originalName: {eq: "Richmond-view-through-back.jpg"}}) { 
            big: fluid (maxWidth: 630, maxHeight: 830, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
            medium: fluid (maxWidth: 435, maxHeight: 1165, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
            small: fluid (maxWidth: 720, maxHeight: 540, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
        }
    
    } 
`


export default AboutUs